import DataObjectIcon from '@mui/icons-material/DataObject';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SchoolIcon from '@mui/icons-material/School';
import BadgeIcon from '@mui/icons-material/Badge';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import MergeIcon from '@mui/icons-material/Merge';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import JoinLeftIcon from '@mui/icons-material/JoinLeft';
import CodeIcon from '@mui/icons-material/Code';
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from '@mui/icons-material/Download';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import TableViewIcon from '@mui/icons-material/TableView';
import PollIcon from '@mui/icons-material/Poll';
import LinkIcon from '@mui/icons-material/Link';
import SyncIcon from '@mui/icons-material/Sync';

import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import FolderIcon from "@mui/icons-material/Folder";
import DatasetIcon from '@mui/icons-material/Dataset';
import AbcIcon from '@mui/icons-material/Abc';

import attributes from './attributes';

// default options for dropdowns
const options = {
    // file_type: [
    //     { value: 'csv', label: 'Comma Separated Values (CSV)', icon: <InsertDriveFileOutlinedIcon /> },
    //     { value: 'txt', label: 'Tab-Delimited File (TXT)', icon: <KeyboardTabIcon /> },
    //     { value: 'json', label: 'JavaScript Object Notation (JSON)', icon: <DataObjectIcon /> }
    // ],
    file_type: [
        { value: 'csv', label: 'Comma Separated Values (CSV)' },
        { value: 'txt', label: 'Tab-Delimited File (TXT)' },
        { value: 'json', label: 'JavaScript Object Notation (JSON)' }
    ],
    actions: [
        { value: 'POST', label: 'Add', icon: <AddIcon /> },
        { value: 'DELETE', label: 'Delete', icon: <ClearIcon /> },
        { value: 'PUT', label: 'Update', icon: <ArrowUpwardIcon /> }
    ]
}

const actions = {
    get_files: {
        label: 'Get File',
        description: 'Load file from SFTP server.',
        icon: <ArchiveIcon />,
        // attributes: [
        //     { name: 'object_key', type: 'input', label: 'File Path', icon: <InsertDriveFileOutlinedIcon /> }
        // ]
        attributes: [
            attributes.object_key
        ]
    },
    convert_files: {
        label: 'Convert File',
        description: 'Convert image files to PDF',
        icon: <ArchiveIcon />,
        attributes: []
    },
    merge_files: {
        label: 'Merge Files',
        description: 'Merge files into single PDF',
        icon: <ArchiveIcon />,
        attributes: []
    },
    delete_files: {
        label: 'Delete Files',
        description: 'Delete files from S3',
        icon: <ArchiveIcon />,
        attributes: []
    },
    get_data: {
        label: 'Get Data',
        description: 'Get data via system API',
        icon: <DownloadIcon />,
        attributes: []
    },
    get_students_gpa: {
        label: 'Get Students GPA',
        description: 'Get student GPA data from Powerschool API',
        icon: <SchoolIcon />,
        attributes: []
    },
    get_global_ids: {
        label: 'Get Global IDs',
        description: 'Get teacher and staff Global ID (OIDC) data from Powerschool API',
        icon: <BadgeIcon />,
        attributes: []
    },
    get_student_enrollments: {
        label: 'Get Student Enrollments',
        description: 'Get student enrollment data from Powerschool Enrollment (Infosnap) API',
        icon: <HowToRegIcon />,
        attributes: [],
        params: [
            'status',
            'tag'
        ]
    },
    post_files: {
        label: 'Post Files',
        description: 'Load files from source to SFTP server.',
        icon: <UnarchiveIcon />,
        attributes: [
            // { name: 'file_type', type: 'select', label: 'File Type', icon: <InsertDriveFileOutlinedIcon />, options: options.file_type, required: true },
            // { name: 'folder', type: 'input', label: 'Folder', icon: <FolderIcon /> }
            attributes.file_type,
            attributes.folder
        ]
    },
    load_data: {
        label: 'Load Data',
        description: 'Load data via system API',
        icon: <UploadIcon />,
        attributes: []
    },
    query: {
        label: 'Query',
        description: 'Get result of SQL query.',
        icon: <JoinLeftIcon />,
        attributes: [
            // { name: 'query', type: 'sqleditor', label: 'SQL Server Query', icon: <JoinLeftIcon /> }
            attributes.query
        ]
    },
    storedprocedure: {
        label: 'Stored Procedure',
        description: 'Run an existing Stored Procedure.',
        icon: <CodeIcon />,
        attributes: []
    },
    update_insert: {
        label: 'Update/Insert',
        description: 'Insert new records and update existing records.',
        icon: <MergeIcon />,
        attributes: []
    },
    update_insert_delete: {
        label: 'Update/Insert/Delete',
        description: 'Insert new records, update existing records, delete missing records.',
        icon: <MergeTypeIcon />,
        attributes: []
    },
    append: {
        label: 'Append',
        description: 'Insert all records to target table.',
        icon: <LibraryAddIcon />,
        attributes: []
    },
    overwrite: {
        label: 'Overwrite',
        description: 'Delete all existing data from target table and insert records from source.',
        icon: <SettingsBackupRestoreIcon />,
        attributes: []
    },
    get_metadata_query: {
        label: 'Get Metadata Query',
        description: 'GraphQL to extract metadata from Tableau Server',
        icon: <DataObjectIcon />,
        attributes: [
            // { name: 'query', type: 'sqleditor', label: 'GraphQL Query', icon: <JoinLeftIcon /> }
            attributes.querygql
        ]
    },
    update_user: {
        label: 'Update User',
        description: 'Update users via API',
        icon: <ManageAccountsIcon />,
        attributes: []
    },
    read_sheets: {
        label: 'Read Sheets',
        description: 'Get data from Google Sheet. Must be in columnar format.',
        icon: <TableViewIcon />,
        attributes: [
            // { name: 'googlesheetId', label: 'Google Sheet ID', required: true, type: 'input', icon: <DatasetIcon /> },
            // { name: 'sheet', label: 'Sheet Name', required: true, type: 'input', icon: <AbcIcon /> }
            attributes.googlesheetId,
            attributes.sheet
        ]
    },
    get_survey_results: {
        label: 'Get Survey Results',
        description: 'Get survey responses from Qualtrics survey.',
        icon: <PollIcon />,
        attributes: [
            // { name: 'surveyId', type: 'input', label: 'Survey ID', required: true },
            // { name: 'DistributionChannel', type: 'input', label: 'Distribution Channel', required: true }
            attributes.surveyId,
            attributes.distributionChannel
        ]
    },
    get_survey_links: {
        label: 'Get Survey Links',
        description: 'Get personalized survey links from Qualtrics survey based on distribution list.',
        icon: <LinkIcon />,
        attributes: [
            attributes.surveyId,
            attributes.distributionChannel
        ]
    },
    contact_import_automation: {
        label: 'Directory Contact Automation',
        description: 'Sync Qualtrics directory contacts with mailing list automation.',
        icon: <SyncIcon />,
        attributes: [
            attributes.directoryId,
            attributes.automationId
        ]
    },
    sync_mailing_list: {
        label: 'Directory Contact Sync - API',
        description: 'Sync Qualtrics directory contacts with API.',
        icon: <SyncIcon />,
        attributes: [
            attributes.directoryId,
            attributes.mailingListId,
            attributes.automationId
        ]
    },
    get_table: {
        label: 'Get Table',
        icon: <TableViewIcon />
    },
    get_report: {
        label: 'Get Report',
        description: 'Get Workday Report (RaaS)',
        icon: <PollIcon />,
        attributes: [
            attributes.object_key
        ]
    }
}

const actionsSorted = Object.keys(actions).sort().reduce(
    (obj, key) => {
        obj[key] = actions[key];
        return obj;
    },
    {}
)

export default actionsSorted;